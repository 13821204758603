.alert {
    position: fixed;
    top: 80px;
    right: -60px;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    z-index: 9999;
  }
  
  .alert.warning {
    background-color: #ff9800;
  }
  
  .alert.error {
    background-color: #f44336;
  }
  
  .alert.success {
    background-color: #4caf50;
  }
  